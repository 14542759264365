import { colors } from './colors'

const base = {
  appearance: 'none',
  outline: 'none',
  display: 'inline-block',
  textAlign: 'center',
  lineHeight: 'inherit',
  textDecoration: 'none',
  fontSize: 'inherit',
  fontFamily: 'itc-avant-garde-gothic-pro, sans-serif',
  letterSpacing: 'button',
  cursor: 'pointer',
  px: 3,
  py: 2,
  width: 278,

  border: 0,
  borderRadius: '4px',
  transitionProperty: 'opacity, box-shadow, transform, background-color',
  transitionDuration: '150ms',
  transitionTimingFunction: 'ease-out',
  '&[disabled]': {
    opacity: 0.5,
    boxShadow: 'none',
    pointerEvents: 'none',
  },
  '&:focus': {
    boxShadow: 'outline',
  },
}
const center = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const theme = {
  breakpoints: ['40em', '48em', '64em', '80em', '92em', '112em'],
  colors,
  buttons: {
    primary: {
      ...base,
      bg: colors.brand.veniceBlue,
      color: 'white',
      height: 45,
      ...center,
      '&:hover': {
        bg: colors.brand.curiousBlue,
        color: 'white!important',
      },
    },
    secondary: {
      ...base,
      bg: colors.brand.curiousBlue,
      color: 'black',
      height: 45,
      ...center,
      '&:hover': {
        bg: 'white',
        color: colors.brand.curiousBlue,
      },
    },
  },
  container: {
    maxWidth: 1200,
    display: 'flex',
    py: [5, null, null, 6],
    px: [4, null, null, null, 0],
  },
  styles: {
    root: {
      bg: 'white',
      fontFamily: 'itc-avant-garde-gothic-pro, sans-serif',
      h1: {
        fontSize: 45,
        fontWeight: 500,
        lineHeight: ['55px', null, null, '65px'],
      },
      h2: {
        fontSize: [32, null, null, 45],
        m: 0,
        fontWeight: 500,
        lineHeight: [1.3, null, null, '65px'],
        textAlign: ['center', null, null, 'left'],
      },
      h3: {
        fontSize: 24,
        m: 0,
        fontWeight: 500,
      },
      a: {
        textDecoration: 'none',
        '&:hover': {
          color: colors.brand.matisse,
          path: {
            cursor: 'pointer',
            fill: colors.brand.matisse,
          },
        },
      },
      p: {
        fontWeight: 300,
        fontSize: [15, null, null, 16],
        lineHeight: [1.4, null, null, 1.5],
      },
    },
  },
}

export default theme
