const grays = [
  '#F6F6F7',
  '#E6E6E6',
  '#E0E1E2',
  '#D8D8DA',
  '#CFCFCF',
  '#B1B1B1',
  '#A4B4B7',
  '#949494',
  '#666666',
  '#4D4D4D',
  '#47525E',
]

const brand = {
  veniceBlue: '#094682',
  curiousBlue: '#1D96D3',
  matisse: '#197C98',
  orange: '#F68635',
  navy: '#0F133C',
  lightBlue: '#CDEAED',
  xLightBlue: '#D7F0EC',
  tealDark: '#1B8575',
  tealLight: '#61C4B4',
  aqua: '#419995',
  sherpa: '#01484B',
  purple: '#452A4F',
  lime: '#C4D600',
  pink: '#E1003A',
  maroon: '#89001F',
  limeDark: '#7F8A37',
}

export const colors = {
  text: '#000',
  grayText: grays[9],
  background: grays[0],
  primary: brand.lime,
  secondary: brand.orange,
  accent: '#197C98',
  placeholder: '#707070',
  brand,
  grays,
}
